// eslint-disable-next-line import/order
import './wdyr';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import { BrowserTracing } from '@sentry/tracing';
// eslint-disable-next-line import/no-unresolved
import 'virtual:vite-plugin-sentry/sentry-config';
import App from './App';
// now you can use this variable like so
const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist;

const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;

ReactGA.initialize('G-VX9ZV8B3FM');

// if (process.env.DISABLE_SENTRY !== 'true') {
//   Sentry.init({
//     dsn: 'https://4647fa2ad7cb4889b274ce8421f07d1d@o1230640.ingest.sentry.io/4504289679966208',
//     integrations: [new BrowserTracing()],
//     environment: process.env.ENV || 'local',
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//     dist,
//     release,
//   });
// }

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
